<template>
  <div class="scoreDetails">
    <div class="bread">
      <span @click="goback()">全部作品</span>
      <img src="../../assets/image/pingwei/left.png" alt="" />
      <span class="span2">打分详情</span>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="csbh">参赛编号：{{ workInfo.bm_id }}</div>
        <div class="time">上传时间：{{ workInfo.addDate }}</div>
        <div class="video">
          <video
            v-if="workInfo.path"
            width="100%"
            height="100%"
            controls
            ref="video"
            style="object-fit: fill"
          >
            <source :src="aliyunURL + workInfo.path" type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
            您的浏览器不支持Video标签。
          </video>
          <p v-else>视频链接已失效</p>
        </div>
        <!-- <div class="videoinfo">{{ workInfo.intro }}</div> -->
      </div>
      <div class="content-right">
        <h2>打分表</h2>
        <div class="scoreBox" v-for="item in scoreList" :key="item.id">
          <div class="title">
            <div class="left">{{ item.username }}</div>
            <div class="right">
              <span v-if="item.score != ''"
                >总分：{{ item.score }}分
                <span v-if="item.type === 1">(最终得分)</span></span
              >
              <span v-else>未打分</span>
            </div>
          </div>
          <ul class="contentbox">
            <li v-for="(item2, index) in item.score_data" :key="index">
              <span class="span1">{{ item2.name }} </span>
              <span class="span2">已打分: {{ item2.scored }}分 </span>
            </li>
          </ul>
          <div
            v-if="item.score != ''"
            class="detailBtn"
            @click="openDialog(item)"
          >
            打分详情
          </div>
        </div>
      </div>
    </div>
    <div class="dialog">
      <el-dialog
        title="评分详情"
        :visible.sync="scoreDetailsDialog"
        v-if="scoreDetailsDialog"
        width="60%"
        :before-close="
          () => {
            scoreDetailsDialog = false;
          }
        "
      >
        <div>
          <scoreruleDetailMod :ScoreList="detailList"></scoreruleDetailMod>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getOpusOne } from "../JudegeAPI/user";
import { getTokenJ } from "../../assets/js/Storage-J";
import scoreruleDetailMod from "../../components/scoreruleDetailMod.vue";
export default {
  components: {
    scoreruleDetailMod,
  },
  data() {
    return {
      aliyunURL: "https://vod.gyycbj.com/",
      workInfo: {},
      scoreList: [],
      scoreDetailsDialog: false,
      detailList: [],
    };
  },
  created() {
    if (this.$route.params.id == undefined) {
      return this.$router.go(-1);
    }
    this.getWorkInfo(this.$route.params.id);
  },
  methods: {
    openDialog(data) {
      this.detailList = data.score_data;
      this.scoreDetailsDialog = true
    },
    async getWorkInfo(workid) {
      const { data: res } = await getOpusOne({
        j_id: getTokenJ().j_id,
        bm_id: workid,
      });
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.workInfo = res.data;
      if (res.data.score instanceof Array) {
        this.scoreList = res.data.score;
      } else {
        this.scoreList[0] = res.data.score;
      }
      this.scoreList.forEach((item) => {
        if (item.score_data != "") {
          item.score_data = JSON.parse(item.score_data);
        }
      });
      console.log(this.scoreList);
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.scoreDetails {
  .dialog {
    ::v-deep .el-dialog__header span {
      display: block;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 20px;
    }
    ::v-deep .el-table__row td:last-child {
      padding: 0;
    }
    ::v-deep .el-dialog__body {
      height: 500px;
      overflow-y: scroll;
    }
    .GradeList {
      display: flex;
      li {
        flex: 1;
      }
    }
    ::v-deep .el-table__row td:last-child {
      // background-color: #f5f7fa;
      color: red;
    }
  }
  .bread {
    background-color: #fff;
    padding: 0 30px;
    height: 70px;
    display: flex;
    align-items: center;
    span {
      cursor: pointer;
      display: block;
      color: #333333;
      font-size: 20px;
      font-weight: 600;
    }
    img {
      width: 42px;
      height: 42px;
      margin: 0 8px;
      margin-top: 3px;
    }
    .span2 {
      color: #5b2d9b;
    }
  }
  .content {
    margin-top: 17px;
    display: flex;
  }
  .content-left {
    flex: 2;
    margin-right: 30px;
    height: 100%;
    padding: 30px;
    background-color: #fff;
    font-size: 20px;
    color: #333333;
    .time {
      padding-bottom: 30px;
    }
    .csbh {
      color: #666;
      padding-bottom: 5px;
    }
    .video {
      width: 100%;
      background-color: #333089;
      height: 500px;
      margin-bottom: 35px;
    }
    .videoinfo {
      height: 150px;
    }
  }

  .content-right {
    background-color: #fff;
    flex: 1.1;
    height: 800px;
    padding: 0 20px;
    overflow-y: scroll;
    h2 {
      font-size: 20px;
      padding: 25px 0;
      text-align: center;
    }
    .scoreBox {
      border: 1px solid #e5e5e5;
      margin-bottom: 20px;
      .title {
        height: 60px;
        line-height: 60px;
        background-color: #f7f8fa;
        text-align: center;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        font-weight: 600;
      }
      .contentbox {
        padding: 0 30px;
        li {
          width: 100%;
          border-bottom: 1px solid #e5e5e5;
          padding: 20px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            display: block;
          }
          .span1 {
            flex: 1;
          }
          .span2 {
            flex-basis: 110px;
            text-align: right;
            padding-left: 10px;
            color: #5b2d9b;
            font-weight: 600;
          }
        }
      }
    }
    .detailBtn {
      width: 136px;
      height: 50px;
      line-height: 50px;
      border: 1px solid #5b2d9b;
      background-color: #5b2d9b;
      border-radius: 5px;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      margin: 15px auto;
      cursor: pointer;
    }
  }
}
</style>
